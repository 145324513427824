import FontFaceObserver from 'fontfaceobserver';

class FontsService {
  loadedFonts = [];

  loadFonts = (fonts) => {
    if (!fonts || !fonts.length) {
      return;
    }

    try {    
      var container = document.createElement('div');
      container.setAttribute('id', 'fontsLoader');
      container.width = 1;
      container.height = 1;
  
      document.body.insertBefore(container, document.getElementById('root'));
  
      let stage = new Konva.Stage({
        container: 'fontsLoader',
        width: 1,
        height: 1,
      });
      let layer = new Konva.Layer();
      stage.add(layer);
      stage.draw();

      fonts.forEach(t => {
        const text = new Konva.Text({
          elementType: 'text',          
          text: 'test text',
          fontFamily: t.family
        });
        layer.add(text);
        stage.draw();

        const fontObserver = new FontFaceObserver(t.family);
        fontObserver.load(null, 3000)
          .then(function () {
            fontsService.loadedFonts.push(t.id);
          })
          .catch(function (e) {
            console.error('Error loading font', t, e);            
          });
      });
      
      container.remove();      
    } catch (e) {
      console.error(e);
    }
  }
}

export const fontsService = new FontsService();