import React, { useEffect, useState } from 'react';
import { Button, Dropdown, message } from 'antd';
import dayjs from 'dayjs';
import { Handle } from 'react-flow-renderer';
import { useHistory } from 'react-router-dom';
import NodeConditionSwitch from '../components/NodeConditionSwitch';
import { NodeSelect } from './NodeSelect';
import { copyDesignTemplate } from '../copyDesignService/copyDesignService';
import { useWebSocket } from '../../../contexts/SocketContext';
import { isOutputsReadyToRun, isProjectRunning } from '../../Project/StatusBlock/StatusBlock';
import PromoCalendar from './PromoCalendar';
import { ReactComponent as CreateNewIcon } from 'svg/node/create-new-node.svg';
import { ReactComponent as EditIcon } from 'svg/node/edit-new.svg';
import { ReactComponent as NodeIcon } from 'svg/node/graphic-node-label.svg';
import { ReactComponent as DeleteIcon } from 'svg/sidebar/trash.svg';
import { ReactComponent as MoreIcon } from 'svg/node/more.svg';
import { ReactComponent as CopyIcon } from 'svg/node/copy-node.svg';
import { ReactComponent as PercentIcon } from 'svg/node/percent.svg';

import styles from './Nodes.module.css';

export default ({ data, isConnectable }) => {
  const [rerender, setRerender] = useState(false);
  const [condition, setCondition] = useState(data.condition ?? 'OR');
  const [promoVisibility, setPromoVisibility] = useState({});
  const organization = data?.project?.organization;
  const history = useHistory();
  const { data: socketData } = useWebSocket();
  const projectId = data.project?.id;
  const isRunning = isProjectRunning(socketData[projectId]?.status);
  const isOutputRunning = isOutputsReadyToRun(socketData[projectId]?.outputs);
  const isButtonShouldDisable =
    !organization.generation_allowed || isRunning || isOutputRunning;

  useEffect(() => {
    setRerender(false);
  }, [rerender]);

  useEffect(() => {
    if (data.promoTemplates && Object.keys(data.promoTemplates).length > 0) {
      const initialPromoVisibility = {};
      Object.keys(data.promoTemplates).forEach((key) => {
        const index = Object.keys(data.promoTemplates).indexOf(key);
        initialPromoVisibility[index] = true;
      });
      setPromoVisibility(initialPromoVisibility);
    }
  }, [data.promoTemplates]);

  if (data.selected !== undefined) {
    data.selected = Array.isArray(data.selected)
      ? data.selected
      : [data.selected];
  } else {
    data.selected = [];
  }

  if (data.promoTemplates !== undefined) {
    data.promoTemplates = typeof data.promoTemplates === 'object'
        ? data.promoTemplates
        : {};
  } else {
    data.promoTemplates = {};
  }

  if (!data.condition) data.condition = 'OR';

  const saveAndGo = (to) => {
    data.save();
    history.push({ pathname: to, search: window.location.search });
  };

  const conditionChanged = (value) => {
    data.condition = value;
    setCondition(value);
  };

  const onAddTemplateClick = () => {
    if (data.selected.length < data.project?.designs.length) {
      data.selected.push(
        data.project?.designs.filter((dataItem) => data.selected.indexOf(dataItem.id) < 0)[0]['id'],
      );
      setRerender(!rerender);
    } else {
      message.warning(`Only ${data.project?.designs.length} designs in project exist`);
    }
  };

  const onCopyTemplate = async (template) => {
    const { id } = template;
    const designs = await data.loadDesigns(true);
    const design = designs.find((template) => template.id === id);

    if (design) {
      await copyDesignTemplate(
        design,
        data.project.id,
        data.loadProject,
        data.loadTemplates,
      );
    }
  };

  const togglePromoVisibility = (index) => {
    setPromoVisibility((prevState) => {
      const newState = { ...prevState, [index]: !prevState[index] };
      const selectedId = data.selected[index];
      if (!newState[index]) {
        delete data.promoTemplates[selectedId];
      }
      return newState;
    });
  };

  const handlePromoChange = (index, value) => {
    const selectedId = data.selected[index];
    if (Object.values(data.promoTemplates).some((template) => template.promo_template === value)) {
      message.warning('This promo design is already selected.');
    }
    if (!data.promoTemplates[selectedId]) {
      data.promoTemplates[selectedId] = {};
    }
    data.promoTemplates[selectedId].promo_template = value;
    setRerender(!rerender);
  };

  const handleDateChange = (index, dates) => {
    const selectedId = data.selected[index];
    if (!data.promoTemplates[selectedId]) {
      data.promoTemplates[selectedId] = {};
    }
    data.promoTemplates[selectedId].start = dayjs(dates[0]).startOf('day').toISOString();
    data.promoTemplates[selectedId].end = dayjs(dates[1]).endOf('day').toISOString();

    setRerender(!rerender);
  };

  return (
    <div className={`${styles.node} ${styles.nodeGraphic}`}>
      <div className={`${styles.nodeHead} ${styles.graphicNodeHead}`}>
        <NodeIcon />
        <span>Graphic</span>
        <NodeConditionSwitch
          condition={condition}
          onConditionChanged={conditionChanged}
          disabled={isButtonShouldDisable}
        />
      </div>
      <div className={`${styles.nodeBody} ${styles.graphicNodeBody}`}>
        {data.selected.map((selectItem, selectIndex) => (
          <div
            className={`${styles.nodeBodyRow} ${styles.nodeBodyRowGraphic}`}
            key={`select_${selectIndex}`}
          >
            <NodeSelect
              className={`${styles.nodeData} ${styles.nodeDataGraphic}`}
              defaultValue={selectItem}
              onChange={(value) => {
                data.selected[selectIndex] = value;
                setRerender(true);
              }}
              disabled={isButtonShouldDisable}
              options={data.project.designs.map((design) => ({
                value: design.id,
                label: design.name,
              }))}
            />
            <div className={styles.nodeButtonList}>
              <Button
                onClick={() => saveAndGo(`/project/${data.project.id}/design/${selectItem}`)}
                disabled={isButtonShouldDisable}
                className={styles.nodeButtonIcon}
              >
                <EditIcon />
              </Button>
              <Button
                onClick={() => onCopyTemplate(data.project.designs.find((design) => design.id === selectItem))}
                className={styles.nodeButtonIcon}
                disabled={isButtonShouldDisable}
              >
                <CopyIcon />
              </Button>
              <Dropdown
                className={styles.dropdown}
                overlayClassName={styles.dropdownOverlay}
                menu={{
                  items: [
                    {
                      key: 'delete',
                      label: (
                        <Button
                          onClick={() => {
                            data.selected.splice(selectIndex, 1);
                            if (data.promoTemplates[data.selected[selectIndex]]) {
                              delete data.promoTemplates[data.selected[selectIndex]];
                            }
                            setRerender(!rerender);
                          }}
                          disabled={isButtonShouldDisable}
                          className={`${styles.dropdownButton} ${styles.dropdownButtonGraphic}`}
                        >
                          <span>Delete</span> <DeleteIcon />
                        </Button>
                      ),
                    },
                  ],
                }}
              >
                <Button>
                  <MoreIcon />
                </Button>
              </Dropdown>
              <Button
                onClick={() => togglePromoVisibility(selectIndex)}
                className={styles.nodeButtonIcon}
                disabled={isButtonShouldDisable}
              >
                <PercentIcon />
              </Button>
              {promoVisibility[selectIndex] && (
                <>
                  <NodeSelect
                    className={`${styles.nodeData} ${styles.nodeDataGraphic} ${styles.nodeDataGraphicPromo}`}
                    defaultValue={data.promoTemplates[data.selected[selectIndex]]?.promo_template || ''}
                    onChange={(value) => handlePromoChange(selectIndex, value)}
                    disabled={isButtonShouldDisable}
                    options={data.project.designs.map((design) => ({
                      value: design.id,
                      label: design.name,
                    }))}
                  />
                  <Button
                    onClick={() => {
                      saveAndGo(
                        `/project/${data.project.id}/design/${data.promoTemplates[data.selected[selectIndex]]?.promo_template}`,
                      );
                    }}
                    disabled={isButtonShouldDisable}
                    className={styles.nodeButtonIcon}
                  >
                    <EditIcon />
                  </Button>
                  <PromoCalendar
                    selectIndex={selectIndex}
                    data={data}
                    handleDateChange={handleDateChange}
                    disabled={isButtonShouldDisable}
                  />
                </>
              )}
            </div>
          </div>
        ))}
        <div className={styles.nodeFooterGraphic}>
          <Button
            className={`${styles.nodeButton} ${styles.nodeButtonGraphic}`}
            onClick={() => saveAndGo(`/project/${data.project.id}/design/new`)}
            disabled={isButtonShouldDisable}
          >
            <span>Create new</span>
            <CreateNewIcon />
          </Button>
          <Button
            className={`${styles.nodeButton} ${styles.nodeButtonGraphic}`}
            onClick={onAddTemplateClick}
            disabled={isButtonShouldDisable}
          >
            <span>Add template</span>
            <CreateNewIcon />
          </Button>
        </div>
      </div>
      <div id='copy-template' />
      <Handle
        type='target'
        position='left'
        className={`${styles.nodeHandle} ${styles.nodeHandleLeft}`}
        isConnectable={isConnectable}
      />
      <Handle
        type='source'
        position='right'
        id='a'
        className={`${styles.nodeHandle} ${styles.nodeHandleRight}`}
        isConnectable={isConnectable}
      />
    </div>
  );
};
